var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"d-flex flex-column w-100 vh-100"},[_c('header',{staticClass:"mw-100"},[_c('c-topbar',{attrs:{"hide-app-selector":"","sidebar-pinned":_vm.pinned,"settings":_vm.$Settings.get('ui.topbar', {}),"labels":{
        helpForum: _vm.$t('help.forum'),
        helpDocumentation: _vm.$t('help.documentation'),
        helpFeedback: _vm.$t('help.feedback'),
        helpVersion: _vm.$t('help.version'),
        userSettingsLoggedInAs: _vm.$t('userSettings.loggedInAs', { user: _vm.user }),
        userSettingsProfile: _vm.$t('userSettings.profile'),
        userSettingsChangePassword: _vm.$t('userSettings.changePassword'),
        userSettingsLogout: _vm.$t('userSettings.logout'),
      }},scopedSlots:_vm._u([{key:"help-dropdown",fn:function(){return [_c('portal-target',{attrs:{"name":"topbar-help-dropdown"}})]},proxy:true}],null,false,1243801212)})],1),_c('main',{staticClass:"d-flex h-100 overflow-hidden"},[_c('c-app-selector',{attrs:{"logo":_vm.logo}})],1)]):_c('c-loader-logo',{attrs:{"logo":_vm.logo}})}
var staticRenderFns = []

export { render, staticRenderFns }